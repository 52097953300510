import { Col } from 'react-bootstrap';

export const ProjectCard = ({ title, description, imgUrl, github, site }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br />
          <a className="btn text-info fs-5 fw-bold" href={github} target="_blank" rel="noreferrer">
            Github
          </a>
          <a className="btn text-info fs-5 fw-bold" href={site} target="_blank" rel="noreferrer">
            Live Site
          </a>
        </div>
      </div>
    </Col>
  );
};
