import { Container, Row, Col, Tab } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import projImg1 from '../assets/img/project-img1.png';
// import projImg2 from '../assets/img/project-img2.png';
// import projImg3 from '../assets/img/project-img3.png';
import projImg4 from '../assets/img/project-img4.png';
import projImg5 from '../assets/img/project-img5.png';
import projImg6 from '../assets/img/project-img6.png';
import projImg7 from '../assets/img/project-img7.png';
import projImg8 from '../assets/img/project-img8.png';
import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const projects = [
    {
      title: 'TechWave',
      description:
        'WildGears is your one-stop TechWave is your go-to social platform for tech enthusiasts, fostering a community where innovation thrives and technology trends are born. 🚀',
      imgUrl: projImg7,
      github: 'https://github.com/al-shaimon/tech-wave-client',
      site: 'https://tech-wave-client.vercel.app/',
    },
    {
      title: 'WildGears',
      description:
        'WildGears is your one-stop online destination for all camping essentials, offering a wide range of products to enhance your outdoor adventures.',
      imgUrl: projImg5,
      github: 'https://github.com/al-shaimon/wild-gears-client',
      site: 'https://wild-gears.vercel.app/',
    },
    {
      title: 'RentRide',
      description:
        'RentRide is your one-stop online destination for renting car in most affordable prize.',
      imgUrl: projImg4,
      github: 'https://github.com/al-shaimon/rent-ride-client',
      site: 'https://rent-ride-client.vercel.app/',
    },
    {
      title: 'HelloTalk',
      description:
        'HelloTalk is a language learning platform. Users can learn new languages by using our site. ',
      imgUrl: projImg6,
      github: 'https://github.com/the-code-wizards/hello_talk_client',
      site: 'https://hello-talk-client.vercel.app/',
    },
    {
      title: 'Diamond Tech Resale',
      description: 'Sell your unused computer components at a reasonable price',
      imgUrl: projImg1,
      github: 'https://github.com/al-shaimon/used-products-resale',
      site: 'https://computer-resale.web.app/',
    },
    {
      title: 'Life Skills Support Connections',
      description:
        'Unlock your full potential with our empowering Life Skills courses! Join us in mastering essential daily life skills to conquer challenges and live a more independent and fulfilling life. 🌟',
      imgUrl: projImg8,
      // github: 'https://github.com/al-shaimon/used-products-resale',
      site: 'https://lifeskillsbc.com/',
    },
    // {
    //   title: 'Doctors Portal',
    //   description: 'Book your appointment and get advice from an expert dentist from our portal',
    //   imgUrl: projImg2,
    //   github: 'https://github.com/al-shaimon/dental-portal-client',
    //   site: 'https://doctors-portal-5a64c.web.app/',
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg1,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h2>Projects</h2>
                  <br />
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                    <Tab.Content
                      id="slideInUp"
                      className={isVisible ? 'animate__animated animate__slideInUp' : ''}
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod
                          neque provident velit, rem explicabo excepturi id illo molestiae
                          blanditiis, eligendi dicta officiis asperiores delectus quasi inventore
                          debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod
                          neque provident velit, rem explicabo excepturi id illo molestiae
                          blanditiis, eligendi dicta officiis asperiores delectus quasi inventore
                          debitis quo.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="" />
    </section>
  );
};
